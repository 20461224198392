<template>
  <page-layout>
    <a-card>
      <a-form-model :model="form" layout="inline">
        <a-row type="flex" justify="space-between" align="middle">
          <a-col>
            <a-button type="primary" @click="addInfo"> 新增开票信息 </a-button>
          </a-col>
          <a-col>
            <a-input-search
              placeholder="输入发票抬头搜索"
              v-model="form.name"
              style="width: 290px"
              @search="getList"
            />
          </a-col>
        </a-row>
      </a-form-model>
      <a-alert
        message="请您和贵司财务人员核实发票信息，确保开票信息填写正确，以免影响发票的后续使用！"
        type="success"
        show-icon
        style="margin-top: 16px"
      >
        <template slot="icon">
          <a-icon type="exclamation-circle" class="circle_icon" />
        </template>
      </a-alert>
      <a-table
        class="mt16"
        rowKey="id"
        :pagination="pagination"
        :columns="columns"
        :data-source="data"
        :loading="loading"
      >
        <template slot="is_default" slot-scope="text, record">
          <a-switch
            v-model="record.defaultChecked"
            @change="(e) => changeDefault(e, record.id)"
          />
        </template>
        <template slot="active" slot-scope="text, record">
          <a type="link" @click="edit(record.id)">编辑</a>
          <a-divider type="vertical" />
          <a type="link" @click="del(record.id)">删除</a>
        </template>
      </a-table>
    </a-card>

    <add-invioce
      ref="addInvioce"
      :id="selectId"
      @subSuccess="subSuccess"
      @waitCity="waitCity"
    />
  </page-layout>
</template>

<script>
const columns = [
  // {
  //   dataIndex: "id",
  //   title: "ID",
  //   key: "id",
  //   width: 80,
  //   align: "center",
  // },
  {
    dataIndex: "header_type",
    title: "抬头类型",
    key: "header_type",
    align: "center",
    customRender: (text) => {
      return text == 1 ? "个人" : "企业";
    },
    width: 120,
  },
  {
    dataIndex: "name",
    title: "发票抬头",
    align: "center",
    key: "name",
    customRender: (text, record) => {
      return record.invoice_name || record.firm_name;
    },
  },
  {
    dataIndex: "add_time",
    title: "添加时间",
    align: "center",
    key: "add_time",
  },
  {
    dataIndex: "is_default",
    title: "设为常用",
    scopedSlots: { customRender: "is_default" },
    align: "center",
    key: "is_default",
  },
  {
    dataIndex: "active",
    title: "操作",
    align: "center",
    key: "active",
    scopedSlots: { customRender: "active" },
    fixed: "right",
    width: 130,
  },
];
import PageLayout from "@/layouts/PageLayout";
import addInvioce from "../components/addInvioce.vue";
import {
  invoiceInfo,
  invoiceDetail,
  invoiceDel,
  setDefault,
} from "@/api/invoice";

export default {
  components: { PageLayout, addInvioce },
  name: "",
  data() {
    return {
      form: {
        page: 1,
        limit: 10,
      },
      columns,
      data: [],
      selectId: "",
      detail: {},
      loading: false,
      pagination: {
        current: 1,
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    del(id) {
      let _this = this;
      this.$confirm({
        title: "提示",
        content: "确定删除该发票？",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          invoiceDel(id).then((res) => {
            if (res.data.status == 200) {
              _this.getList();
            } else {
              this.$message.error(res.data.msg);
            }
          });
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    changeDefault(e, id) {
      let params = {
        isDefault: e ? 1 : 0,
      };
      setDefault(id, params).then((res) => {
        if (res.data.status == 200) {
          this.$message.success("设置成功");
          this.getList();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    waitCity() {
      let params = {
        province_id: Number(this.detail.province_id),
        city_id: Number(this.detail.city_id),
        district_id: Number(this.detail.district_id),
      };
      this.$nextTick(() => {
        this.$refs.addInvioce.addressForm = params;
        this.$refs.addInvioce.changeAddress(params);
      });
    },
    edit(id) {
      this.selectId = id;
      const hide = this.$message.loading("加载中...", 0);
      invoiceDetail(id)
        .then((res) => {
          this.$refs.addInvioce.visible = true;
          this.$refs.addInvioce.isEdit = true;
          let data = res.data.data;
          this.detail = data;
          this.$refs.addInvioce.form1 = {
            headerType: data.header_type + "",
            invoiceName: data.invoice_name,
            invoicePhone: data.invoice_phone,
            invoiceEmail: data.invoice_email,
            isDefault: data.is_default,
            addressDetail: data.address_detail,
            firmBank: data.firm_bank,
            firmBankSn: data.firm_bank_sn,
            firmDutyNumber: data.firm_duty_number,
            firmEmail: data.firm_email,
            firmName: data.firm_name,
            firmPhone: data.firm_phone,
            provinceId: Number(data.province_id),
            cityId: Number(data.city_id),
            districtId: Number(data.district_id),
          };
          console.log(this.$refs.addInvioce.form1);
          this.$refs.addInvioce.is_default = data.is_default == 1;
        })
        .finally(() => {
          hide();
        });
    },
    subSuccess() {
      this.getList();
    },
    addInfo() {
      this.selectId = 0;
      this.$refs.addInvioce.visible = true;
      this.$refs.addInvioce.isEdit = false;
    },
    getList() {
      this.loading = true;
      invoiceInfo(this.form)
        .then((res) => {
          this.data = res.data.data.list.map((item) => {
            item.defaultChecked = item.is_default == 1;
            return item;
          });
          this.pagination.total = res.data.data.count;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSearch(e) {
      this.form.name = e;
    },
  },
};
</script>

<style scoped lang="less">
.ant-alert-success {
  background-color: #f3fdf9;
  border: 1px solid #3ab887;
  color: #3ab887 !important;

  .circle_icon {
    color: #3ab887;
  }
}
div /deep/ .ant-alert-icon {
  top: 8.5px;
}
.mt16 {
  margin-top: 16px;
}
</style>
